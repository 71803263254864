import { useFormik } from "formik";
import { ReactElement, useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { IdentificationMethodEnum } from "../../../../types/profile";
import Container from "../../../atoms/Container";
import ScreenTitle from "../../../atoms/ScreenTitle";
import InscriptionFields from "../../../molecules/Home/Inscription/InscriptionFields";
import ContactFields from "../../../molecules/Home/Inscription/ContactFields";
import InscriptionIdentifier from "../../../molecules/Home/Inscription/InscriptionIdentifier";
import NextPrevButtons from "../../../molecules/NextPrevButtons";
import * as Yup from "yup";
import { postConsentRequestType, UserStatus } from "../../../../types/welcome";
import { validateUserInfo } from "../../../../services/user.service";
import { useConfig } from "../../../../contexts/ConfigContext";
import { useHomeContext } from "../../../../pages/Home/HomeContent";
import { useAuth } from "../../../../contexts/AuthContext";
import AnonymousData from "../../../molecules/Home/Consent/DisplayAnonymousData";
import { useResonanceLayout } from '../../../organisms/ResonnanceLayout/ResoncanceContext';

interface DisplayInscriptionProps {
  onNextClick: (data: any) => void;
  saveUserInfos: (values: any) => void;
  userInfo: postConsentRequestType;
}

const DisplayInscription = ({
  userInfo,
  saveUserInfos,
  onNextClick,
}: DisplayInscriptionProps): ReactElement => {
  const { t } = useTranslation();

  const { selectedRole, study } = useConfig();
  const { welcomeData, goPrevious } = useHomeContext();

  const studyId = welcomeData.id;
  const attributes = selectedRole?.attributes;
  const signinCriteria = selectedRole?.signin_criteria;
  const invitations = welcomeData.invitations;
  const onPreviousClick = goPrevious;
  const { user: userAuth } = useAuth();

  const onSubmit = async (values) => {
    try {
      const user: any = {};
      const identification_questions = [];

      if (attributes.has_firstname) user.firstname = values.firstname;
      if (attributes.has_lastname) user.lastname = values.lastname;
      if (attributes.has_nickname) user.nickname = values.nickname;
      if (attributes.has_email) user.email = values.email;
      if (attributes.has_phonenumber) user.phone_number = values.phone_number;
      if (attributes.has_external_id) user.external_id = values.external_id;

      if (true)
      {
        if (user.email == "") user.email = "user_" + userAuth.id + "@etudementalo.fr"
        if (user.phone_number == "") user.phone_number = "0607080910"
      }

      if (
        invitations?.to_user?.user_status !==
        UserStatus.INCLUSION_EN_ATTENTE_DE_VALIDATION
      ) {
        if (
          signinCriteria.method === IdentificationMethodEnum.SECRET_QUESTIONS
        ) {
          signinCriteria.identification_questions.forEach((question) => {
            identification_questions.push({
              id: question.id,
              answer: values[question.id],
              version: 1,
            });
          });
        }

        if (signinCriteria.method === IdentificationMethodEnum.EMAIL ||  signinCriteria.method === IdentificationMethodEnum.NICKNAME_PASSWORD) {
          user.password = values.password;
        }
      }

        await validateUserInfo({ studyId: studyId }, user);

      saveUserInfos({ user, identification_questions });
      onNextClick({ user, identification_questions, userAuth });
    } catch (e) {
      console.log(e.response.status);
      switch (e.response.status) {
        case 400:
          console.log(e.response.data);
          if(e.response!="undefined"){
            if(e.response.data.email!=null){
              setErrors({
                email: t("home_inscription_error_" + e.response.data.email)
              });
            }
            if(e.response.data.nickname!=null){
              setErrors({
                nickname: t("home_inscription_error_" + e.response.data.nickname),
              });
            }
          }
          break;
      }
    }
  };

  const initialValues = useMemo(
    function () {
      let obj: any = {
        firstname: "",
        lastname: "",
        email: "",
        phone_number: "",
        nickname: "",
        external_id: "",
      };

      if (
        invitations?.to_user?.user_status !==
        UserStatus.INCLUSION_EN_ATTENTE_DE_VALIDATION
      ) {
        if (
          signinCriteria.method === IdentificationMethodEnum.SECRET_QUESTIONS
        ) {
          signinCriteria.identification_questions.forEach((question) => {
            obj[question.id] = "";
          });
        }

        if (signinCriteria.method === IdentificationMethodEnum.EMAIL ||  signinCriteria.method === IdentificationMethodEnum.NICKNAME_PASSWORD) {
          obj.password = "";
          obj.confirmPassword = "";
        }
      }

      if (userInfo?.user) obj = { ...obj, ...userInfo.user };
      obj.confirmPassword = obj.password;

      if (userInfo?.identification_questions) {
        userInfo.identification_questions.forEach((question) => {
          obj[question.id] = question.answer;
        });
      }

      return obj;
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [signinCriteria]
  );

  const validationSchema = useMemo(
    function () {
      const obj: any = {};

      if (attributes.has_firstname) obj.firstname = Yup.string().required();
      if (attributes.has_lastname) obj.lastname = Yup.string().required();
      if (attributes.has_nickname) obj.nickname = Yup.string().required();

      if (false)
      {
        // if (attributes.has_email) obj.email = Yup.string().email().required();
        // if (attributes.has_phonenumber)
        //   obj.phone_number = Yup.string()
        //     .matches(/(^06|^07)[0-9]*$/, "form_error_phone")
        //     .required();
      }
      else
      {
        if (attributes.has_email && !attributes.has_phonenumber) 
        {  
          obj.email = Yup.string().email().required();
        }
        else if (attributes.has_phonenumber && !attributes.has_email)
        {   
          obj.phone_number = Yup.string()
            .matches(/(^06|^07)[0-9]*$/, "form_error_phone")
            .required();
        }
        else if (attributes.has_phonenumber && attributes.has_email)
        {
          obj.email = Yup.string().email().test(
            'either-email-or-phone',
            'form_error_email_or_phone_required',
            function (value) {
              return value || this.parent.phone_number;
            }
          );
          obj.phone_number = Yup.string()
            .matches(/(^06|^07)[0-9]*$/, 'form_error_phone')
            .test(
              'either-email-or-phone',
              'form_error_email_or_phone_required',
              function (value) {
                return value || this.parent.email;
              }
            );    
        }
      }

      if (attributes.has_external_id) obj.external_id = Yup.string().required();

      if (
        invitations?.to_user?.user_status !==
        UserStatus.INCLUSION_EN_ATTENTE_DE_VALIDATION
      ) {
        if (
          signinCriteria.method === IdentificationMethodEnum.SECRET_QUESTIONS
        ) {
          signinCriteria.identification_questions.forEach((question) => {
            obj[question.id] = Yup.string().required();
          });
        }

        if (signinCriteria.method === IdentificationMethodEnum.EMAIL ||  signinCriteria.method === IdentificationMethodEnum.NICKNAME_PASSWORD) {
          obj.password = Yup.string()
            .min(8)
            .matches(
              /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[^A-Za-z0-9])(?!.*\s).{8,100}$/,
              "form_error_email_password"
            )
            .required();
          obj.confirmPassword = Yup.string()
            .required()
            .oneOf([Yup.ref("password")], "form_error_email_confirm_password");
        }
      }

      return Yup.object().shape(obj);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [attributes]
  );

  const { values, touched, errors, submitForm, setFieldValue, setErrors } =
    useFormik({
      initialValues,
      onSubmit,
      validationSchema,
    });
    
  useEffect(() => {
    if (selectedRole.signin_criteria.method === "WITHOUT_IDENTIFICATION") {
      onSubmit({
        firstname: "",
        lastname: "",
        email: "",
        phone_number: "",
        nickname: "",
        external_id: "",
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const { setResonanceLayout } = useResonanceLayout();

  useEffect(() => {
    setResonanceLayout({ title: "home_inscription_title" });
  }, []);

  return (
    <Container>
      <div>
        <InscriptionFields
          values={values}
          attributes={attributes}
          setFieldValue={setFieldValue}
          errors={errors}
          touched={touched}
        />

        {invitations?.to_user?.user_status !==
          UserStatus.INCLUSION_EN_ATTENTE_DE_VALIDATION && (
            <InscriptionIdentifier
              setFieldValue={setFieldValue}
              values={values}
              errors={errors}
              touched={touched}
              signinCriteria={signinCriteria}
            />
          )}

        <ContactFields
          values={values}
          attributes={attributes}
          setFieldValue={setFieldValue}
          errors={errors}
          touched={touched}
        />
      </div>

      <AnonymousData />

      <NextPrevButtons
        onNextClick={submitForm}
        onPreviousClick={onPreviousClick}
      />
    </Container>
  );
};

export default DisplayInscription;
