import { ReactElement, useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useAuth } from "../../contexts/AuthContext";
import { useConfig } from "../../contexts/ConfigContext";
import { useFetch } from "../../hooks/useFetch";
import VeriticalCenter from "../../components/atoms/VerticalCenter";
import Container from "../../components/atoms/Container";
import Info from "../../components/atoms/ErrorComponent";
import ResonanceLoader from "../../components/atoms/ResonanceLoader/ResonanceLoader";
import DisplayContent from "../../components/molecules/DisplayContent";
import DisplayMountain from "../../components/organisms/Home/DisplayMountain";
import { useResonanceLayout } from "../../components/organisms/ResonnanceLayout/ResoncanceContext";
import { postUserGamification } from "../../services/user.service";
import PointModal from "../Profile/PointsModal";
import { ComponentType } from "../../types/Questionnaire";
import { getQuestionnairesByComponents } from "../../services/questionnaire.service";
import StoryTelling from "../../components/molecules/Home/StoryTellingModal";
import DisplayThankYouParticipation from "../../components/organisms/Invitation/DisplayThankYouParticipation";
import { UserGamificationDetails } from "../../types/welcome";
import { isMandatoryQuestionnaire } from "../../helper/questionnaire";
import { getStudyContent } from "../../services/study.service";
import { StudyContentType } from "../../types/welcome";
import { useHistory, useParams } from "react-router";

const LoguedHome = (): ReactElement => {
  const { study } = useConfig();
  const { user } = useAuth();
  const {
    t,
    i18n: { language: lang },
  } = useTranslation();
  const history = useHistory();
  const { study_slug } = useParams<{ study_slug: string }>();

  const [pointToDisplay, setPointToDisplay] = useState(0);
  const [animationCompleted, setAnimationCompleted] = useState(false);
  const [isPointModalOpen, setIsPointModalOpen] = useState(false);
  const [isThankYouParticipationModalOpen, setIsThankYouParticipationModalOpen] = useState(false);
  const [isStoryTellingModalOpen, setIsStoryTellingModalOpen] = useState(false);
  const [disableHeaderFinished, setDisableHeaderFinished] = useState(false);
  const [storyTellingFinished, setStoryTellingFinished] = useState(false);
  const { setResonanceLayout, userGamificationDetails, questionnaires } = useResonanceLayout();

  const gamificationFetch = useFetch<ComponentType[]>("questionnairesBDD", getQuestionnairesByComponents, {
    studyId: study.id,
    userId: user.id,
    lang,
  });

  const contentFetch = useFetch<StudyContentType>("sequenceContent", getStudyContent, {
    studyId: study.id,
    contentId: user.homepage_id,
    lang,
  }, { enabled: Boolean(user.homepage_id) });

  
  const { data:questionnairesBDD } = gamificationFetch
  useEffect(() => {
    if(questionnairesBDD)
    {
      if (questionnaires === undefined)
        setResonanceLayout({ questionnaires: questionnairesBDD });
    }

    return () => {};
  }, [questionnairesBDD]);

  // Gamification logic
  useEffect(() => {
    if (study.is_gamified) {
      setResonanceLayout({
        disableHeader: true,
        disableNav: false,
        backgroundColor: "white",
      });
      setDisableHeaderFinished(true);
    }
    return () => {
      setResonanceLayout({
        disableHeader: false,
        disableNav: false,
        backgroundColor: "white",
      });
    };
  }, [study.is_gamified]);

  useEffect(() => {
    const updateUserGamification = async () => {
      try {
        const { data: userGamificationBDD } = await postUserGamification(study.id, user.id, null);
        if (userGamificationBDD) {
          const userGamificationUpdates: UserGamificationDetails = {
            points: userGamificationBDD.points,
            step: userGamificationBDD.step,
            animation_success: userGamificationBDD.animation_success,
            pointsEarned: 0,
            stepEarned: 0,
            animation_successEarned: 0,
          };
          setResonanceLayout({ userGamificationDetails: userGamificationUpdates });
        }
      } catch (e) {
        console.error("Error updating user gamification: ", e);
      }
    };

    if (userGamificationDetails === undefined) {
      updateUserGamification();
    }
  }, [userGamificationDetails, study.id, user.id]);

  const handleAnimationComplete = (value: boolean) => {
    setAnimationCompleted(value);
  };

  useEffect(() => {
    if (animationCompleted && pointToDisplay > 0) {
      setIsPointModalOpen(true);
    }
  }, [animationCompleted, pointToDisplay]);

  const checkPointEarned = () => {
    if (userGamificationDetails.pointsEarned > 0) {
      setPointToDisplay(userGamificationDetails.pointsEarned);
      if (userGamificationDetails.animation_success === 0) {
        setIsThankYouParticipationModalOpen(true);
      }
    }
  };

  const checkStoryTelling = () => {
    if (userGamificationDetails.points === 0 && userGamificationDetails.step === 0) {
      setIsStoryTellingModalOpen(true);
    } else {
      setIsStoryTellingModalOpen(false);
      setStoryTellingFinished(true);
    }
  };

  useEffect(() => {
    if (study.is_gamified && userGamificationDetails) {
      checkPointEarned();
      checkStoryTelling();
    }
  }, [userGamificationDetails, study.is_gamified]);

  useEffect(() => {
    if (storyTellingFinished && questionnaires && userGamificationDetails) {
      for (const questionnaire of questionnaires) {
        if (isMandatoryQuestionnaire(questionnaire.keywords)) {
          history.push(
            `/${study_slug}/resonance/sequences/${questionnaire.sequenceId}/components/${questionnaire.id}`
          );
          break;
        }
      }
    }
  }, [questionnaires, storyTellingFinished, userGamificationDetails, history, study_slug]);

  // Rendering logic
  if (study.is_gamified) {
    if (gamificationFetch.isLoading) return <ResonanceLoader />;
    if (gamificationFetch.isError) {
      return (
        <Info
          title={t("common_server_error_title")}
          description={t("common_server_error_desc")}
          actionText={t("common_go_back")}
        />
      );
    }

    return (
      <Container>
        <VeriticalCenter>
          {isThankYouParticipationModalOpen ? (
            <DisplayThankYouParticipation onDismiss={() => setIsThankYouParticipationModalOpen(false)} />
          ) : (
            <>
              <PointModal
                isOpen={isPointModalOpen}
                onDismiss={() => setIsPointModalOpen(false)}
                points={pointToDisplay}
              />
              <StoryTelling
                isOpen={isStoryTellingModalOpen}
                onDismiss={() => setIsStoryTellingModalOpen(false)}
                animate={() => {
                  setIsStoryTellingModalOpen(false);
                  setStoryTellingFinished(true);
                }}
              />
              <DisplayMountain
                onAnimationComplete={handleAnimationComplete}
                disableHeaderFinished={disableHeaderFinished}
              />
            </>
          )}
        </VeriticalCenter>
      </Container>
    );
  } else {
    if (contentFetch.isLoading) return <ResonanceLoader />;
    if (contentFetch.isError) {
      return (
        <Info
          title={t("common_server_error_title")}
          description={t("common_server_error_desc")}
          actionText={t("common_go_back")}
        />
      );
    }

    return <DisplayContent editorJsData={contentFetch.data?.content} />;
  }
};

export default LoguedHome;
