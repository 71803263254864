import { FC, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory, useParams } from "react-router";
import styled, { useTheme } from "styled-components";
import Container from "../../../atoms/Container";
import VeriticalCenter from "../../../atoms/VerticalCenter";
import { useResonanceLayout } from "../../ResonnanceLayout/ResoncanceContext";
import NextPrevButtons from "../../../molecules/NextPrevButtons";
import { ANDROIDTUTO1, ANDROIDTUTO2, IOSTUTO1, IOSTUTO2 } from "../../../../constants/images";

const DisplayRegistredSuccessfully: FC = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const { study_slug } = useParams<{ study_slug: string }>();
  const { setResonanceLayout } = useResonanceLayout();
  const theme = useTheme();

  const [isMobile, setIsMobile] = useState(false);
  const [deviceType, setDeviceType] = useState<'iOS' | 'Android' | 'Desktop'>('Desktop');

  const handleOnClick = () => {
    history.replace(`/${study_slug}/resonance`);
  };

  useEffect(() => {
    setResonanceLayout({
      disableHeader: true,
      disableNav: true,
      backgroundColor: theme["primary"].background,
    });

    return () =>
      setResonanceLayout({
        disableHeader: false,
        disableNav: false,
        backgroundColor: "white",
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const userAgent = navigator.userAgent || navigator.vendor || (window as any).opera;

    if (/android/i.test(userAgent)) {
      setIsMobile(true);
      setDeviceType('Android');
    } else if (/iPad|iPhone|iPod/.test(userAgent) && !(window as any).MSStream) {
      setIsMobile(true);
      setDeviceType('iOS');
    } else {
      setIsMobile(false);
      setDeviceType('Desktop');
    }
  }, []);

  const renderContent = () => {
    if (deviceType === 'iOS') {
      return (
        <>
          <Subtitle>Etape 1</Subtitle>          
          <ScreenShot src={IOSTUTO1} alt="iOS tuto " />
          <Subtitle>Etape 2</Subtitle> 
          <ScreenShot src={IOSTUTO2} alt="iOS tuto" />
        </>
      );
    } else if (deviceType === 'Android') {
      return (
        <> 
          <Subtitle>Etape 1</Subtitle>         
          <ScreenShot src={ANDROIDTUTO1} alt="Android tuto " />
          <Subtitle>Etape 2</Subtitle> 
          <ScreenShot src={ANDROIDTUTO2} alt="Android tuto" />
        </>
      );
    } else {
      return (
        <>
        </>
      );
    }
  };
  
  return (
    <Container>
      <VeriticalCenter style={{alignItems: "center", marginTop: '5vh'}}>
        <Icon alt="registered successfully" color="white" />
        <Title>{t("home_registred_successfully")}</Title>

        <Group>
          <Text>Ajoute Maintenant Tabapsy sur ton écran d'accueil.</Text>
          <MentaLogo src="assets/images/logo.png" alt="" />
        </Group>

      {renderContent()}
      <Subtitle>Et le tour est joué !</Subtitle>
      {/* <ScreenShot src="assets/images/screenshot-registered-successfully.png" alt=""/> */}
      <NextPrevButtons
        onNextClick={handleOnClick}
        />
        </VeriticalCenter>
    </Container>
  );
};

const Subtitle = styled.h3`
  font-weight : bold;
`
const ScreenShot = styled.img`
  height: auto;
  width: auto;
  max-width: 70vw;
`
const MentaLogo = styled.img`
  height: auto;
  width: auto;
  max-width: 75%;
  max-height: 75%;
  margin-top: 10px;
`
const Text = styled.p`
  font-size: 1.25em;
  margin-right: 15px;
`
const Group = styled.div`
  display: flex;
  flex-direction: row;
  width: 70vw;
  margin-bottom: 30px;
  align-items: center;

   @media (min-width: 1200px) {
    width: 50vw;
  }
`;

const Icon = styled.img`
  width: 100px;
  margin: 0;
  content: url(/assets/images/svg/verified-blue.svg);
`
const Title = styled.div`
  font-size: 24px;
  padding: 0 20px;
  font-weight: bold;
  text-align: center;
  margin-top: 20px;
  margin-bottom: 10px;
  color:${(props) => props.theme.primary.contrast};
`;

export default DisplayRegistredSuccessfully;

