import { Capacitor } from "@capacitor/core";

/** 
 *  /!\ the url scheme must be all lowercase, without spaces or special characters
 */
const DEFAULT_SLUG = "tabapsy"
const DEFAULT_URL_SCHEME = "tabapsy"

export function getStudySlug() {
    if (Capacitor.isNativePlatform()) {
        return DEFAULT_SLUG;
    }
    const queryString = window.location.pathname.split("/");
    const [, slug] = queryString;
    return slug;
};

export function getUrlScheme() {
    return DEFAULT_URL_SCHEME.toLowerCase();
};
