import { ReactElement } from 'react';
import styled from "styled-components";
import { useTranslation } from 'react-i18next';
import { ICON_SHIELD_CHECKMARK } from  '../../../../constants/images';

const AnonymousData = ({style}:{style?:any}): ReactElement => {
    const { t } = useTranslation();

  return (
    <ContainerBorder style={style}>
        <Image height="40px" src={ICON_SHIELD_CHECKMARK}></Image>
        <SubTitle>{t("home_anonymous_data")}</SubTitle>
    </ContainerBorder>

  );
};

const SubTitle = styled.div`
  font-weight: bold;
  font-size: 18px;
  margin: 10px 10px 10px 5px;
  display: flex;
  justify-content: center;
`;

const ContainerBorder = styled.div`
  margin: 10% 10% 10% 10%;
  display: flex;
  flex-direction : row;
  align-items: center;
  border: 2px solid #f4a197;
  border-radius: 15px;
  oveflow: hidden;
`

const Image = styled.img`
  margin: 0px 5px 0px 10px;
  float: left;
`

export default AnonymousData;
