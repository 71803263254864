import { useMemo } from "react";
import { ThemeProvider } from "styled-components";
import { theme } from "../theme/theme";
import { useConfig } from "./ConfigContext";

const themeToColors = {
  // BLUE: {
  //   primary: {
  //     main: "#122CD1",
  //     background: "#122CD1",
  //     contrast: "#fff",
  //   },
  //   secondary: {
  //     main: "#FFD500",
  //     contrast: "#041723",
  //   },
  //   svg: {
  //     verified: 'verified-blue',
  //     finished: 'finished-blue'
  //   }
  // },
  BLUE : {
    primary: {
      main: "#747474",
      background: "#b5fffc",
      contrast: "#041723",
    },
    secondary: {
      main: "#fecf48",
      contrast: "#041723",
    },
    svg: {
      verified: 'verified-blue',
      finished: 'finished-blue'
    }
  },
  GREEN: {
    primary: {
      main: "#0D8C9B",
      background: "#0D8C9B",
      contrast: "#fff",
    },
    secondary: {
      main: "#E76538",
      contrast: "#fff",
    },
    svg: {
      verified: 'verified-green',
      finished: 'finished-green'
    }

  },
  RED: {
    primary: {
      main: "#f2b994",
      background: "#f2b994",
      contrast: "#041723",
    },
    secondary: {
      main: "#041723",
      contrast: "#f2b994",
    },
    svg: {
      verified: 'verified-red',
      finished: 'finished-red'
    }
  },
  MENTALO : {
    primary: {
      main: "#747474", // Gris
      background: "#b5fffc", //Bleu clair 
      contrast: "#041723", //Noir
    },
    secondary: {
      main: "#fecf48", // Jaune
      background:"#F1F0F0", // Gris clair
      contrast: "#041723", //Noir
    },
    svg: {
      verified: 'verified-green',
      finished: 'finished-green'
    },
  },
  TABAPSY : {
    primary: {
      main: "#747474", // Gris
      background: "#CDEBF3", //Bleu clair 
      contrast: "#041723", //Noir
    },
    secondary: {
      main: "#F4A197", // Jaune
      background:"#F1F0F0", // Gris clair
      contrast: "#041723", //Noir
    },
    svg: {
      verified: 'verified-green',
      finished: 'finished-green'
    }, 
  }
}

// change scss variables to match theme
const changeTheme = (theme: string) => {
  const root = document.documentElement;
  const themeColors = themeToColors[theme];
  root.style.setProperty("--ion-color-primary", themeColors.primary.main);
  root.style.setProperty("--ion-color-primary-contrast", themeColors.primary.contrast);
};

const Theme = ({ children }) => {
  const { app } = useConfig();

  const modifiedTheme = useMemo(
    function () {
      changeTheme(app?.theme ?? 'TABAPSY');
      if (!app?.theme) return theme;
      else
        return {
          ...theme,
          ...themeToColors[app.theme],
        };
    },
    [app]
  );

  return <ThemeProvider theme={modifiedTheme}>{children}</ThemeProvider>;
};

export default Theme;
