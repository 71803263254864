import { IonCheckbox } from "@ionic/react";
import { ReactElement, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { useConfig } from "../../../../contexts/ConfigContext";
import { useFetch } from "../../../../hooks/useFetch";
import { useHomeContext } from "../../../../pages/Home/HomeContent";
import { getStudyContent } from "../../../../services/study.service";
import { StudyContentType, WelcompeStepEnum } from "../../../../types/welcome";
import Container from "../../../atoms/Container";
import Info from "../../../atoms/ErrorComponent";
import ResonanceLoader from "../../../atoms/ResonanceLoader/ResonanceLoader";
import DisplayContent from "../../../molecules/DisplayContent";
import NextPrevButtons from "../../../molecules/NextPrevButtons";
import { useResonanceLayout } from "../../ResonnanceLayout/ResoncanceContext";
import DisplayAnonymousData from "../../../molecules/Home/Consent/DisplayAnonymousData";
import { postUserGamification } from "../../../../services/user.service";
import { useAuth } from "../../../../contexts/AuthContext";
import ConsentCheckbox from "../../../molecules/Home/Consent/ConsentCheckbox";
import ConsentChoice from "../../../molecules/Home/Consent/ConsentChoice";
import ConsentScroll from "../../../molecules/Home/Consent/ConsentScroll";

interface DisplayConsentProps {
  onNextClick: (obj) => void;
  setConsent: (value: any) => void;
}

const DisplayConsent = ({
  onNextClick,
  setConsent,
}: DisplayConsentProps): ReactElement => {
  const {
    t,
    i18n: { language },
  } = useTranslation();

  const { selectedRole } = useConfig();
  const { welcomeData, goPrevious, setCurrentStep } = useHomeContext();

  const consent = selectedRole?.consent;
  const studyId = welcomeData.id;
  const { user } = useAuth();

  const { id } = consent;
  const [consentValue, setConsentValue] = useState(null);
  const [consentType,setConsentType] = useState(null);
  const { setResonanceLayout } = useResonanceLayout();

  const {
    isLoading,
    isError,
    data: contentData,
  } = useFetch<StudyContentType>("consent-content", getStudyContent, {
    studyId,
    contentId: id,
    lang: language,
  });

  useEffect(function () {
    setConsentType(selectedRole.consent.type);
    setResonanceLayout({
      title: t("home_consent_title"),
    });

    return () =>
      setResonanceLayout({
        title: undefined,
      });
  }, []);

  useEffect(()=> {
    console.log('ConsentValue : ',consentValue)
  },[consentValue])

  if (isLoading) return <ResonanceLoader />;

  if (isError)
    return (
      <Info
        title={t("common_server_error_title")}
        description={t("common_server_error_desc")}
        actionText={t("common_go_back")}
      />
    );

  const createUserGamification = async (): Promise<void> => {
    try {
      const { data: gamification } = await postUserGamification(
        studyId, 
        user.id, 
        {
          points: 0,
          step: 0,
          animation_success: 0
      });

      if (gamification)
        console.log("Success : gamification not null");
      else
        console.log("Error : gamification null");

      console.log("Success : UserGamification Creation");  
    } catch (e) {
      console.error("Error createUserGamification : " + e);
    }
  };

  const { content } = contentData;

  const handleConsent = async () => {
    if(consentValue){
      const cons = {
        id: consent.id,
        is_validated: consentValue,
        validation_date: new Date().toISOString(),
        version: contentData.version,
        signature: "signature",
      };
  
      createUserGamification();
      setConsent(cons);
      onNextClick(cons);
    } else {
      return;
    }

  };

  return (
    <Container>
      <div style={{ padding: "20px" }}>

      <div>
          <DisplayContent editorJsData={content} />
        </div>

        
        <DisplayAnonymousData /> 

        {
          consentType === 'CHECKBOX' && (
            <ConsentCheckbox consentValue={consentValue} setConsentValue={setConsentValue}/>
          )
        }

        {
          consentType === 'CHOICE' && (
            <ConsentChoice consentValue={consentValue} setConsentValue={setConsentValue} />
          )
        }
      </div>

      <NextPrevButtons
        onPreviousClick={goPrevious}
        onNextClick={handleConsent}
        disableNext={!consentValue}
      />
    </Container>
  );
};

export default DisplayConsent;
